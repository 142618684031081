import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import TraxersList from '../../components/traxers/traxers-list';
import ScrollTo from '../../components/common/scroll-to';

const PioneeringTraxers = ({ data }) => {
  return (
    <>
      <BackgroundImage Tag="div" fluid={data.hero.childImageSharp.fluid}>
        <div className="container mx-auto h-full my-16">
          <div className="flex flex-wrap text-white text-center">
            <div className="w-full p-5 mt-40 mb-20 text-center">
              <h1 className="font-serif">
                Read the best stories of the decade!
              </h1>
              <p className="lg:max-w-lg mx-auto mt-6">
                Our pioneering Traxers shared some insightful messages with us.
                Read along and get inspired by them!
              </p>
              <ScrollTo to="traxers" offset={-150} className="cursor-pointer ">
                <img
                  src="/icons/arrow_down.svg"
                  width="20"
                  className="mx-auto mt-10 transform hover:translate-y-2 duration-200"
                  alt="10 things we love most about Trax"
                />
              </ScrollTo>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div id="traxers" className="container">
        <TraxersList traxers={data.traxers.nodes} />
      </div>
    </>
  );
};

export const query = graphql`
  query PioneeringTraxers {
    hero: file(relativePath: { regex: "/hero.jpg/" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1442) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    traxers: allStrapiPioneeringTraxers(
      sort: { order: DESC, fields: updated_at }
    ) {
      nodes {
        title
        content
        lead
        strapiId
        updated_at(formatString: "YYYY-MM-DD")
        employee {
          id
          name
          position
        }
        thumbnail {
          childImageSharp {
            fixed(height: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;

export default PioneeringTraxers;
