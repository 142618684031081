/* eslint-disable camelcase */
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import slugify from 'slugify';
import { Employee } from '../common';

const TraxerCard = ({ traxer }) => {
  const { title, lead, employee, thumbnail, updated_at } = traxer;

  const link = `/pioneering-traxers/${slugify(title).toLowerCase()}`;

  return (
    <div className="px-4 mb-12 w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
      <div className="mx-auto" style={{ maxWidth: 370 }}>
        <div className="overflow-hidden rounded-md" style={{ height: 250 }}>
          <Img fixed={thumbnail.childImageSharp.fixed} alt="story" />
        </div>
        <h3 className="pt-6 pb-2">{title}</h3>
        <div className="flex mb-6">
          <div className="w-1/2">
            <Employee employee={employee} />
          </div>
          <div className="w-1/2">
            <p className="text-sm font-light text-right">{updated_at}</p>
          </div>
        </div>
        <p className="font-light text-base">{lead}</p>
        <Link className="font-medium hover:underline mb-2" to={link}>
          READ MORE
        </Link>
      </div>
    </div>
  );
};

export default TraxerCard;
