import React from 'react';
import TraxerCard from './traxer-card';

const TraxersList = ({ traxers }) => {
  return (
    <div className="flex content-center flex-wrap xl:justify-between">
      {traxers.map((traxer) => (
        <TraxerCard key={traxer.strapiId} traxer={traxer} />
      ))}
    </div>
  );
};

export default TraxersList;
